<!-- <app-header-four></app-header-four> -->
<app-header-one></app-header-one>

<div class="main-banner-section height_image item-bg1666">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>ASSURANCE SANTE</h1>
                    <p>Elle couvre la prise en charge des frais médicaux pharmaceutiques et d’hospitalisation à la suite d’une maladie. La dite maladie étant définie comme une altération de l’état de santé dûment constatée par une autorité médicale.</p>

                    <div class="btn-box" *ngIf="!login && donnee != 'sante'">
                        <!-- <div (click)="choixmrh('pack')" class="default-btn default-btn1">Obtenir un devis
                            <span></span>
                        </div> -->
                        <a (click)="choixmrh('pack')" class="default-btn btnO cursor">Obtenir un devis<span></span></a>
                        <a class="optional-btn souscription c-cursor" (click)="sendPlainte()">Remonter une plainte<span></span></a>
                        <!-- <a class="optional-btn rappel">Me faire rappeler<span></span></a> -->
                    </div>
                    <div class="btn-box" *ngIf="login && donnee != 'sante'">
                        <!-- <div (click)="choixmrh('pack')" class="default-btn default-btn1">Obtenir un devis
                            <span></span>
                        </div> -->
                        <a (click)="choixmrh('pack')" class="default-btn btnO cursor">Obtenir un devis<span></span></a>
                        <a class="optional-btn souscription">Remonter une plainte<span></span></a>
                        <!-- <a class="optional-btn rappel">Me faire rappeler<span></span></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>





<div class="row desc container-row justify-content-center" id="contact" #contact>
    <div class="col-12 droite" *ngIf="login">
        <form [formGroup]="souscriptionForm" class="auto">

            <aw-wizard class="stepper-container pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">

                <!--  step 1 Initialisation -->

                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Initialisation">
                    <div class="form-container">
                        <div class="container">
                            <div class="VeuFont" *ngIf="!visiblepack">Veuillez choisir votre assureur</div>
                            <div class="VeuFont" *ngIf="visiblepack">Souscrivez-vous sur un pack</div>
                            <section class="team-area ptb-70 pb-70 paddingTop" *ngIf="!visiblepack">
                                <div class="container mt-5">
                                    <div class="row c-cards">
                                        <div class="col-md-3 cardB badg-n" [class.selected]="currentCodeAs == ass.assureur.code" *ngFor="let ass of assureurs" (click)="getF(ass?.assureur?.id,ass?.assureur?.code)">
                                            <div class="image-container">
                                                <div class="first">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="discount">{{ass?.assureur?.code}}</span>
                                                    </div>
                                                </div>
                                                <img *ngIf="ass.assureur.img" [src]="ass.assureur.img" class="img-fluid rounded thumbnail-image tailImgB">
                                                <img *ngIf="!ass.assureur.img" src="../../../../assets/img/amsa.png" class="img-fluid rounded thumbnail-image tailImgB">
                                            </div>
                                            <div class="product-detail-container p-2">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <h5 class="dress-name">{{ass?.assureur?.nom}}</h5>
                                                    <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="form-container" formGroupName="AxASimulateur" *ngIf="choose ">
                                <div class="row info-perso">
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                        <div class="form" placement="top" ngbPopover="Type de formule" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-users form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <select id="cars" placeholder=" " class="form__select" formControlName="typeFormule" (change)="selectFormuleSimul($event)">
                                                <option value="" selected disabled>Type de formule</option>
                                                <option [value]="formuleSante[0].id">{{formuleSante[0].nom}}</option>
                                                <option [value]="formuleSante[1].id" [selected]="true">
                                                    {{formuleSante[1].nom}}</option>
                                            </select>
                                            <label for="cars" class="form__label">Type de formule <span
                                                    class="text-red"> *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('typeFormule').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                        <div class="form" placement="top" ngbPopover="Formule" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-user form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <select id="cars" placeholder=" " class="form__select" formControlName="formule">
                                                <option value="" selected disabled>Formule</option>
                                                <option [value]="tf.id"
                                                    *ngFor="let tf of typeFormuleAssure; let i=index;">{{tf.nom}}
                                                </option>
                                            </select>
                                            <label for="cars" class="form__label">Formule<span class="text-red">
                                                    *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('formule').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!solo">
                                        <div class="form" placement="top" ngbPopover="Age famille" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-user form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <select id="cars" placeholder=" " class="form__select" formControlName="isOld">
                                                <option value="" selected disabled>Famille âgée de</option>
                                                <option value="1">plus de 59 ans</option>
                                                <option value="0">moins de 59 ans</option>
                                            </select>
                                            <label for="cars" class="form__label" *ngIf="souscriptionForm.get('AxASimulateur').get('isOld').valid">Famille
                                                âgée de<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('isOld').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="solo">
                                        <div class="form" ngbPopover="Date de naissance" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-calendar form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" id="age" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1950, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'AxASimulateur','age')" [value]="" ngbDatepicker #dateAss="ngbDatepicker"
                                                (click)="dateAss.toggle()" />
                                            <label for="age" class="form__label">Date de naissance<span
                                                    class="text-red"> *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('age').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!solo">
                                        <div class="form" ngbPopover="Nombre d'adultes" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-user form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="number" min="0" id="nombreAdulte" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreAdulte" />
                                            <label for="nombreAdulte" class="form__label">Nombre d'adultes<span
                                                    class="text-red"> *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('nombreAdulte').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!solo">
                                        <div class="form" ngbPopover="Nombre d'enfants" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-user form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="number" min="0" id="nombreEnfant" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreEnfant" />
                                            <label for="nombreEnfant" class="form__label">Nombre d'enfants<span
                                                    class="text-red"> *</span></label>
                                        </div>
                                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('AxASimulateur').get('nombreEnfant').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div class="row container-row justify-content-center mt-0 mb-0">
                                        <button type="button" class="btn btn-success btn-next simulateurSuiv" (click)="validerInitial()"> Suivant
                                            <i class="fa fa-angle-double-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <section class="pricing-area ptb-70 pb-70 mt-2" *ngIf="!choose && visiblepack">
                                <div class="container">
                                    <div class="row" *ngFor="let ass of assureurs">
                                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let pack of ass?.assureur?.devis">
                                            <div class="single-pricing-box" *ngIf="currentAssure === ass?.assureur?.id">
                                                <div class="pricing-header bg-{{pack?.formule?.code}}" (click)="selectFormule(ass, pack)">
                                                    <h3>{{pack?.formule?.nom}}</h3>
                                                </div>
                                                <div class="c-price">
                                                    <sub>{{pack?.prix |number:'1.0-0':'fr'}} &nbsp;
                                                        FCFA</sub>
                                                </div>

                                                <div class="book-now-btn" (click)="selectFormule(ass, pack)">
                                                    <span class="default-btn">Souscrire</span>
                                                </div>
                                                <ul class="pricing-features-list">
                                                    <li class="tehgr" (click)="getPrestation(pack?.formule?.description,'avantage')">
                                                        Les avantages

                                                    </li>
                                                    <li class="tehgr" *ngIf="pack?.formule?.nom ==='Pack Familiale Gold' || 'Gold'">
                                                        Les prestations
                                                        <a class="lienCSS" href=".././../../../../assets/doc/Garanties-Tarifs-SANTE-FAMILLE-OR.pdf" download>
                                                            <i class="fa fa-download ico-"></i>
                                                        </a>
                                                    </li>
                                                    <li class="tehgr" *ngIf="pack?.formule?.nom ==='Gold Sante'">Les prestations
                                                        <a class="lienCSS" href=".././../../../../assets/doc/ReseauprestataireNSIASANTEFAMILLE.pdf" download>
                                                            <i class="fa fa-download ico-"></i>
                                                        </a>
                                                    </li>
                                                    <li class="tehgr" *ngIf="pack?.formule?.nom ==='Gold Sante'">Les prestations
                                                        <a class="lienCSS" href=".././../../../../assets/doc/ReseauprestataireNSIASANTEFAMILLE.pdf" download>
                                                            <i class="fa fa-download ico-"></i>
                                                        </a>
                                                    </li>
                                                    <li class="tehgr">Les prestations
                                                        <a class="lienCSS" href=".././../../../../assets/doc/ReseauprestataireNSIASANTEFAMILLE.pdf" download>
                                                            <i class="fa fa-download ico-"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="row justify-content-center" *ngIf="visiblepack">
                                <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn c-btnWidth" awPreviousStep (click)="retourInit()" [disabled]="getDevisLoader">
                                    <i class="fa fa-chevron-left"></i>Retour
                                </button>
                            </div>
                        </div>
                    </div>

                </aw-wizard-step> -->




                <!--  step 2 Assurés -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assurés" *ngIf="choose">
                    <div class="container" *ngIf="!solo">
                        <div class="col-12">
                            <div class="row no-margin">
                                <div class="col-12">
                                    <div class="formulaire c-formulaire">
                                        <h4 class="text-center">Veuillez télécharger et remplir la fiche maladie svp!
                                            <a href=".././../../../../assets/doc/Questionnaire INDIVIDUELLE ACCIDENTS.docm" download><i class="fa fa-download"></i></a>
                                        </h4>
                                        <p class="mt-4" [hidden]="attachments?.length"></p>
                                        <div>
                                            <div [hidden]="attachments?.length" class="text-center">Charger le formulaire
                                                <input type="file" accept=".PDF,.pdf,.doc,.DOC,.docm,.DOCM,.docx,.DOCX" name="attachments" id="attachment_file" [disabled]="attachments.length !== 0" (change)="addAttachment($event)" /></div>
                                            <div [hidden]="!attachments?.length">
                                                <label for="field_attachments"></label>
                                                <dl *ngFor="let attachment of attachments; let i = index" class="row justify-content-center c-fileName" [ngClass]="{ 'bg-light text-muted': attachment.processing }">
                                                    <dt class="col-md-7 col-sm-9">
                                                        <span class="c-name" *ngIf="!attachment.id">{{
                                                            attachment.originalFilename }}</span>
                                                        <span
                                                            class="badge badge-pill badge-light">{{attachment.sizeInBytes
                                                            | filesize}}</span>
                                                    </dt>
                                                    <dt class="col-md-3 col-sm-1">
                                                        <button type="button" class="btn btn-sm c-btn-danger pull-right"
                                                            *ngIf="!attachment.processing"
                                                            (click)="attachments.splice(i, 1)">
                                                            <i class="fa fa-times"></i></button>
                                                        <span *ngIf="attachment.processing">
                                                            <i class="spinner"></i>Reading file &hellip;
                                                        </span>
                                                    </dt>
                                                </dl>
                                            </div>
                                        </div>
                                        <p class="text-danger  mt-2 text-center " *ngIf="noatt">Veuillez charger le formulaire de la fiche médicale svp!</p><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <div class="table-wrapper">
                                <div class="table-title">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <h2>Liste des assurés </h2>
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" class="btn btn-info add-new c-add-new" (click)="conjChoosen()" [disabled]="conjoits.length !== 0 && checkAdulte === nombreAdulte && checkEnfant === nombreEnfant">
                                                <i class="fa fa-plus"></i> Ajouter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="c-head">
                                            <th>Numéro</th>
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            <th>Type</th>
                                            <th>Naissance</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let c of conjoits; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                            <td>{{i+1}}</td>
                                            <td>{{c.prenom}}</td>
                                            <td>{{c.nom}}</td>
                                            <td>{{c.type}}</td>
                                            <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                            <td>
                                                <a class="add" title="Add" data-toggle="tooltip"><i
                                                        class="material-icons"></i></a>
                                                <a class="edit" title="Edit" data-toggle="tooltip" (click)="edit(c,i)"><i class="fa fa-pencil"></i></a>
                                                <a class="delete" title="Delete" data-toggle="tooltip" (click)="deleteSimulateurAssurer(c,i)"><i
                                                        class="fa fa-trash-o"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="text-center" *ngIf="conjoits.length === 0">la liste des assurés est vide</p>
                            </div>
                        </div>
                    </div>
                    <div class="row beneficiary-section form-container " formGroupName="assure" *ngIf="solo">
                        <div class="col-12">
                            <div class="row no-margin">
                                <div class="col-12">
                                    <div class="formulaire c-formulaire">
                                        <h4 class="text-center">Veuillez télécharger et remplir la fiche maladie svp!
                                            <a href=".././../../../../assets/doc/Questionnaire INDIVIDUELLE ACCIDENTS.docm" download><i class="fa fa-download"></i></a>
                                        </h4>
                                        <p class="mt-4" [hidden]="attachments?.length"></p>
                                        <div>
                                            <div [hidden]="attachments?.length" class="text-center">Charger le formulaire
                                                <input type="file" accept=".PDF,.pdf,.doc,.DOC,.docm,.DOCM,.docx,.DOCX" name="attachments" id="attachment_file" [disabled]="attachments.length !== 0" (change)="addAttachment($event)" /></div>
                                            <div [hidden]="!attachments?.length">
                                                <label for="field_attachments"></label>
                                                <dl *ngFor="let attachment of attachments; let i = index" class="row c-fileName justify-content-center" [ngClass]="{ 'bg-light text-muted': attachment.processing }">
                                                    <dt class="col-md-7 col-sm-9">
                                                        <span class="c-name" *ngIf="!attachment.id">{{
                                                            attachment.originalFilename }}</span>
                                                        <span
                                                            class="badge badge-pill badge-light">{{attachment.sizeInBytes
                                                            | filesize}}</span>
                                                    </dt>
                                                    <dt class="col-md-3 col-sm-1">
                                                        <button type="button"
                                                            class="btn btn-sm btn-dange c-btn-danger pull-right"
                                                            *ngIf="!attachment.processing"
                                                            (click)="attachments.splice(i, 1)">
                                                            <i class="fa fa-times"></i></button>
                                                        <span *ngIf="attachment.processing">
                                                            <i class="spinner"></i>Reading file &hellip;
                                                        </span>
                                                    </dt>
                                                </dl>
                                            </div>
                                        </div>
                                        <p class="text-danger  mt-2 text-center" *ngIf="noatt">Veuillez charger le formulaire de la fiche médicale svp!</p><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row no-margin">
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
                                            <option selected disabled>Type</option>
                                            <option value="1">Pour moi-même</option>
                                            <option value="2">Pour un tiers</option>
                                        </select>
                                        <label for="cars" class="form__label">Type<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                        <i class="fa-solid fa-venus form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                            <option value="" selected disabled>Sexe</option>
                                            <option value="M">Masculin</option>
                                            <option value="F">Féminin</option>
                                        </select>
                                        <label for="cars" class="form__label">Sexe<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="prenomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                        <label for="prenomAssure" class="form__label">Prénom<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                        <label for="nomAssure" class="form__label">Nom<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-phone form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="numeroTelephoneAssure" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                        <label for="numeroTelephoneAssure" class="form__label">Numéro téléphone<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-envelope form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                        <label for="email" class="form__label">Email<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                        Ce champ est obligatoire ou invalide
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker"
                                            (click)="dateAssu.toggle()" [value]="souscriptionForm.get('assure').get('dateNaissance').value | date: 'dd/MM/yyyy'" />
                                        <label for="dateNaissance" class="form__label">Date de naissance<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-vcard form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                        <label for="profession" class="form__label">Profession<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-briefcase form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                        <label for="secteuractivite" class="form__label">Secteur activité<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-home form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                        <label for="adresse" class="form__label">Adresse<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- contentResultsign -->
                    <ng-template #addAssures let-modal>
                        <form [formGroup]="souscriptionForm">
                            <div class="container px-4 py-2 mx-auto">
                                <div class="card card0 c-card">
                                    <div class="row beneficiary-section form-container " formGroupName="assure" *ngIf="conjoits.length === 0">
                                        <div class="row no-margin">
                                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                                                <div class="row no-margin">
                                                    <div class="col-10 p-0">
                                                        <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                                    </div>
                                                    <div class="col-lg-2 text-right">
                                                        <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right c-close"><i
                                                                class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
                                                        <option selected disabled>Type</option>
                                                        <option value="1">Pour moi-même</option>
                                                        <option value="2">Pour un tiers</option>
                                                    </select>
                                                    <label for="cars" class="form__label">Type<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                    <i class="fa-solid fa-venus form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                                        <option value="" selected disabled>Sexe</option>
                                                        <option value="M">Masculin</option>
                                                        <option value="F">Féminin</option>
                                                    </select>
                                                    <label for="cars" class="form__label">Sexe<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                                    <label for="prenom" class="form__label">Prénom<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                                    <label for="nom" class="form__label">Nom<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-phone form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                                    <label for="numeroTelephone" class="form__label">Numéro
                                                        téléphone<span class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-envelope form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                                    <label for="email" class="form__label">Email<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                                    Ce champ est obligatoire ou invalide
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                                    <i class="fa fa-calendar form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker"
                                                        (click)="dateAssu.toggle()" />
                                                    <label for="dateNaissance" class="form__label">Date de
                                                        naissance<span class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-vcard form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                                    <label for="profession" class="form__label">Profession<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-briefcase form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                                    <label for="secteuractivite" class="form__label">Secteur
                                                        activité<span class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-home form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                                    <label for="adresse" class="form__label">Adresse<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint('assure')" *ngIf="!updateConj"><i
                                                    class="fa fa-plus"></i> Ajouter</button>
                                            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)" *ngIf="updateConj"><i
                                                    class="fa fa-pencil"></i> Modifier</button>
                                        </div>
                                    </div>
                                    <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="conjoits.length > 0">
                                        <div class="row no-margin">
                                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                                                <div class="row no-margin">
                                                    <div class="col-10 p-0">
                                                        <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                                    </div>
                                                    <div class="col-lg-2 text-right">
                                                        <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                                                class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" placement="top" ngbPopover="Type" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" formControlName="type">
                                                        <option selected>Type</option>
                                                        <option value="enfant" *ngIf="checkEnfant < nombreEnfant">Enfant
                                                        </option>
                                                        <option value="adulte" *ngIf="checkAdulte < nombreAdulte">Adulte
                                                        </option>
                                                    </select>
                                                    <label for="cars" class="form__label">Type<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('type').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                    <i class="fa-solid fa-venus form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                                        <option value="" selected disabled>Sexe</option>
                                                        <option value="M">Masculin</option>
                                                        <option value="F">Féminin</option>
                                                    </select>
                                                    <label for="cars" class="form__label">Sexe<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                                    <label for="prenom" class="form__label">Prénom<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="nomBenef" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                                    <label for="nomBenef" class="form__label">Nom<span class="text-red">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-home form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                                    <label for="adresse" class="form__label">Adresse<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                                    <i class="fa fa-calendar form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [value]="souscriptionForm.get('beneficiaire').get('dateNaissance').value | date:'dd/MM/yyyy'" [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')"
                                                        ngbDatepicker #datebenef="ngbDatepicker" (click)="datebenef.toggle()" />
                                                    <label for="email" class="form__label">Date de naissance<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-phone form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                                    <label for="numeroTelephone" class="form__label">Numéro de
                                                        téléphone<span class="text-red"
                                                            *ngIf="champsObligatoire == false"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid  && champsObligatoire == false">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-envelope form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                                    <label for="email" class="form__label">Email<span class="text-red"
                                                            *ngIf="champsObligatoire == false"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('email').invalid && champsObligatoire == false">
                                                    Ce champ est obligatoire ou invalide
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-user form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                                    <label for="profession" class="form__label">Profession<span
                                                            class="text-red" *ngIf="champsObligatoire == false">
                                                            *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid  && champsObligatoire == false">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-briefcase form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                                    <label for="secteuractivite" class="form__label">Secteur
                                                        activité<span class="text-red"
                                                            *ngIf="champsObligatoire == false"> *</span></label>
                                                </div>
                                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid  && champsObligatoire == false ">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint('beneficiaire')" *ngIf="!updateConj"><i
                                                    class="fa fa-plus"></i> Ajouter</button>
                                            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)" *ngIf="updateConj"><i
                                                    class="fa fa-plus"></i> Modifier</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                    <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="false">
                        <div class="row no-margin">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                                <a (click)="conjointChoosen = false" class="text-right"><i
                                        class="fa fa-times text-danger times-ass c-times-ass"></i></a>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                    <label for="email" class="form__label">Prénom<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                    <label for="email" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner le date de naissance" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker #datebenef="ngbDatepicker"
                                        (click)="datebenef.toggle()" />
                                    <label for="email" class="form__label">Date de naissance<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                    <label for="numeroTelephone" class="form__label">Numéro de téléphone<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                    <label for="profession" class="form__label">Profession<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                    <label for="secteuractivite" class="form__label">Secteur activité<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addConjoint('assure')" *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                            <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)" *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
                        </div>

                    </div>

                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()" [disabled]="getDevisLoader"><i class="fa fa-chevron-left"></i>
                                Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" (click)="validerSiulateuradulte()" [disabled]="getDevisLoader">
                                <i class="fa fa-spinner fa-pulse" *ngIf="getDevisLoader"></i>
                                Suivant
                                <i class="fa fa-angle-double-right"></i>
                            </button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!--  step 3 Assureur -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur" *ngIf="choose">
                    <div class="container">
                        <h6 class="titleAssureur" *ngIf="listAssureurs.length !== 0">Veuillez choisir votre assureur pour obtenir votre devis</h6>
                        <h6 class="titleAssureur" *ngIf="listAssureurs.length === 0" class="text-center">Désolé y'a pas d'assureur disponible pour votre choix !!!</h6>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12" *ngFor="let res of listAssureurs" (click)="selectAssureur(res.id,res)">
                                <div id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave" popoverTitle="{{res.assureur.code}}">
                                    <div [class]="idAssureur ===  res.assureur.id ? 'card rounded selectAssureur c-selectAssureur' : 'card rounded c-selectAssureur'">
                                        <div class="card-image">
                                            <img class="img-fluid " [src]="res.img" alt="{{res.assureur.nom}}" height="177px" />
                                        </div>
                                        <div class="c-card-ass my-name text-center">
                                            <h6 class="nom">{{res.assureur.nom}}</h6>
                                        </div>
                                        <div class="c-card-ass my-price text-center">
                                            <h6 class="nom1">Durée:&nbsp; <span *ngIf="res.assureur.parametrageSante[0].primeNette">
                                                    1&nbsp;an</span>
                                                <span *ngIf="!res.assureur.parametrageSante[0].primeTtc" class="fa fa-spinner fa-pulse"> </span>
                                            </h6>
                                        </div>
                                        <div class="c-card-ass my-price text-center mb-3">
                                            <h6 class="nom1">Prix:&nbsp; <span *ngIf="res.assureur.parametrageSante[0].primeNette">
                                                    {{res.assureur.parametrageSante[0].primeTtc |number:'1.0-0':'fr'}}
                                                    &nbsp;
                                                    FCFA</span> <span *ngIf="!res.assureur.parametrageSante[0].primeTtc" class="fa fa-spinner fa-pulse"> </span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" (click)="validerSiulateur1()">Suivant <i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- *ngIf="choose" step 3 Assuré -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assuré" *ngIf="false">
                    <div class="form-container" formGroupName="assure">
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " (change)="setAssure($event)">
                                        <option value="" selected disabled>Type</option>
                                        <option value="1">Pour moi-même</option>
                                        <option value="2">Pour un tiers</option>
                                    </select>
                                    <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                    <label for="email" class="form__label">Prénom<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                    <label for="email" class="form__label">Numéro de téléphone<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()"
                                    />
                                    <label for="email" class="form__label">Date de naissance<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-vcard form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                    <label for="email" class="form__label">Profession<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                    <label for="email" class="form__label">Secteur activité<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                    <label for="email" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest " (click)="validerassure()">Suivant</button>
                        </div>
                    </div>

                </aw-wizard-step>

                <!-- Enfants step 5  -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Enfants" *ngIf="false">
                    <h6 class="col-12 text-center text-primary mb-2">Famille {{souscriptionForm.get('assure').get('nom').value}}
                    </h6>
                    <div class="col-12">
                        <table class="table">
                            <thead>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Actions</th>
                                <th *ngIf="choix"><a (click)="beneficiaryChoosen = true"><i
                                            class="fa fa-plus text-primary"></i></a>
                                </th>
                            </thead>
                            <tbody>
                                <tr class="table-active" *ngFor="let ben of beneficiares; let index = index">
                                    <td>{{ben.nom}}</td>
                                    <td>{{ben.prenom}}</td>
                                    <td *ngIf="index != 0"><a (click)="delete(index)"><i
                                                class="fa fa-times text-danger"></i></a></td>
                                    <td *ngIf="index == 0">-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container-lg" *ngIf="!enfantChoosen">
                        <div class="table-responsive">
                            <div class="table-wrapper">
                                <div class="table-title">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <h2>Liste enfants</h2>
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" class="btn btn-info add-new" (click)="enfChoosen()" [disabled]="enfants.length === souscriptionForm.get('AxASimulateur').get('nombreEnfant').value && !enfantSupplementaire">
                                                <i class="fa fa-plus"></i> Ajouter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="c-head">
                                            <th>Numéro</th>
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            <th>Naissance</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let enf of enfants; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                            <td>{{i+1}}</td>
                                            <td>{{enf.prenom}}</td>
                                            <td>{{enf.nom}}</td>
                                            <td>{{enf.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                            <td>
                                                <a class="add" title="Add" data-toggle="tooltip"><i
                                                        class="material-icons"></i></a>
                                                <a class="edit" title="Edit" data-toggle="tooltip" (click)="editEnfant(enf,i)"><i class="fa fa-pencil"></i></a>
                                                <a class="delete" title="Delete" data-toggle="tooltip" (click)="deleteSimulateurAssurer('enfant',i)"><i
                                                        class="fa fa-trash-o"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row beneficiary-section form-container " formGroupName="beneficiaireEnfantAxa" *ngIf="enfantChoosen">
                        <div class="row no-margin ">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                                <a (click)="enfantChoosen = false" class="text-right"><i
                                        class="fa fa-times text-danger times-ass c-times-ass"></i></a>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                    <label for="email" class="form__label">Prénom<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner le date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date de naissance">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'beneficiaireEnfantAxa','dateNaissance')" ngbDatepicker #datebenef="ngbDatepicker"
                                        (click)="datebenef.toggle()" />
                                    <label for="email" class="form__label">Date de naissance<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-mobile form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                    <label for="email" class="form__label">Numéro de téléphone<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                    <label for="email" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                    <label for="email" class="form__label">Profession<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                    <label for="email" class="form__label">Secteur d'activité<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaireEnfantAxa').get('secteuractivite').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <button type="button" class="btn  btn-large c-btnAjout" (click)="addEnfantSimul()" *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                            <button type="button" class="btn  btn-large c-btnAjout" (click)="updateEnfantSimul()" *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
                        </div>

                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-right c-roundbtn" [disabled]="sendingRequest || souscriptionForm.get('assure').invalid" (click)="devisSimulateur()"> <i class="fa fa-spinner fa-pulse"
                                    *ngIf="sendingRequest"></i>Voir 1 devis</button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!-- Paiement step 4  -->

                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Paiement" *ngIf="choose">

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-5 p-0 badge-content" (click)="domicile()" [class.selected]="souscriptionForm.get('livraison').value == 'Domicile'">
                            <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                            <div class="badge">
                                Domicile
                            </div>
                        </div>
                        <div class="mt-5 p-0 badge-content" (click)="relais()" [class.selected]="souscriptionForm.get('livraison').value == 'Relais'">
                            <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                            <i class="fa fa-home form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                            </select>
                            <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                    *</span></label>
                        </div>
                        <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                        <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                            <i class="fa fa-home form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                            <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                    class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && souscriptionForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>


                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement 22</h6>
                        <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="souscriptionForm.get('moyenDePaiement').value == pay.code">
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step> -->

                <!-- Récapitulatif step 5  -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif" *ngIf="choose">
                    <div class="recap-form-container">

                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Proposition Sante</div>
                                <div class="sub-title">(Cette proposition est valable pour une année )
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Références du souscripteur
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('prenom')?.value}} {{souscriptionForm?.get('assure')?.get('nom')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('email')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('numeroTelephone')?.value}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('profession')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('secteuractivite')?.value}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{currentAssureur?.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type formule</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{dataSimulateur.typeFormule}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Formule</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{dataSimulateur.formule}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an(s)
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                            </div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="!solo">
                                                Conjoint(s)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="!solo">
                                                {{conjoits.length}}</div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="!solo">
                                                Enfant(s)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="!solo">
                                                {{enfants.length}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        <span> Identification du produit </span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.formule}}
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type formule
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.typeFormule}}
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Service
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.service}}
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.age}} an(s)
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Cout piéce
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.coutPiece}} Fcfa
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Taxe</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.taxe}} Fcfa
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime Nette
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.primeNette}} Fcfa
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime TTC
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{dataSimulateur.primeTtc}} Fcfa
                                                    </div>
                                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Nom </span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="goTo(0)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                                                {{item.nom}}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Plafond (FCFA)
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                                                {{item.plafond |number:'1.0-0':'fr'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Fréquence
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                                                {{item.frequence}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="disposition">
                                <div class="title-disp">
                                    Dispositions complémentaires
                                </div>
                                <div class="desc-disp">
                                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.
                                    </div>
                                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-devis row">
                            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                <div class="fist-line">
                                    <b class="name-devis">{{currentAssureur?.nom}}</b> est une
                                    <b>{{currentAssureur?.typeSociete}}</b> avec conseil d'administration au capital de
                                    <b>{{convertStringToNumber(currentAssureur?.capital) |
                                        number:"1.0-0":'fr'}}</b>. Elle a été créée en
                                    <b>{{currentAssureur?.anneeCreation}}</b>. NINEA
                                    <b>{{currentAssureur?.ninea}}</b>-RC: <b>{{currentAssureur?.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à
                                    <b>{{currentAssureur?.adresse}}</b>.
                                </div>
                                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur?.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                    <b>{{currentAssureur?.email}}</b>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                <img class="footer-img" [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscriptionSimulation" [disabled]="sendingRequest"> <i
                                    class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!--Récapitulatif step 2  -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Souscripteur" *ngIf="!choose">
                    <div class="form-container" formGroupName="nombreAssure">

                        <div class="row info-perso">


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Type" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" formControlName="typeFamille" (change)="getTypeFamille(souscriptionForm.get('nombreAssure').get('typeFamille').value)">
                                        <option value="" selected disabled>Type d’offre</option>
                                        <option value="1">Famille</option>
                                        <option value="2">Pour une personne</option>
                                    </select>
                                    <label for="cars" class="form__label">Type d’offre<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('nombreAssure').get('typeFamille').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Date de début du contrat" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [minDate]="today" (dateSelect)="onDateSelect($event, 'contrat','debut')" ngbDatepicker #dateAss="ngbDatepicker" (click)="dateAss.toggle()" />
                                    <label for="email" class="form__label">Date début contrat<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('contrat').get('debut').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>

                        <div class="row info-perso" *ngIf="souscriptionForm.get('nombreAssure').get('typeFamille').value != ''">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="souscriptionForm.get('nombreAssure').get('typeFamille').value == '1'">
                                <div class="form" ngbPopover="Nombre d'enfants" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="0" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreEnfant" />
                                    <label for="email" class="form__label">Nombre d'enfants<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('nombreAssure').get('nombreEnfant').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="souscriptionForm.get('nombreAssure').get('typeFamille').value == '1'">
                                <div class="form" ngbPopover="Nombre d'adulte" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="0" id="nombreAdulte" class="form__input" autocomplete="off" placeholder=" " formControlName="nombreAdulte" />
                                    <label for="nombreAdulte" class="form__label">Nombre d'adulte<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('nombreAssure').get('nombreAdulte').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="validersous()">Suivant <i
                                    class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré1" *ngIf="false">
                    <div class="form-container" formGroupName="assure">
                        <div class="row assurance-for justify-content-right" *ngIf="true">
                            <div class="radio-toolbar col-12">
                                <input type="radio" id="moi" name="radioFruit" value="1" checked>
                                <label for="moi" class="c-moi-meme" #forMe (click)="setAssure(1)">Pour moi-même</label>
                                <input type="radio" id="tiers" name="radioFruit" value="2">
                                <label for="tiers" class="c-tiers" (click)="setAssure(2)">Pour un tiers</label>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
                                        <option value="" selected disabled>Type</option>
                                        <option value="1">Pour moi-même</option>
                                        <option value="2">Pour un tiers</option>
                                    </select>
                                    <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                    <label for="email" class="form__label">Prénom<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">

                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">

                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                    <label for="email" class="form__label">Numéro de téléphone<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()"
                                    />
                                    <label for="email" class="form__label">Date de naissance<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-vcard form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                    <label for="email" class="form__label">Profession<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                    <label for="email" class="form__label">Secteur activité<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                    <label for="email" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [disabled]="sendingRequest " (click)="validerassure()">Suivant</button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- Assuré step 4  -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assuré" *ngIf="!choose">
                    <div class="" *ngIf="!showDevice">
                        <div class="row posiCards">
                        </div>
                        <div class="row" *ngIf="!beneficiaryChoosen">
                            <div class="col-12 justify-content-right form-group label-position" *ngIf="!choix">
                            </div>
                            <div class="col-12">
                                <div class="container-lg">
                                    <div class="table-responsive">
                                        <div class="table-wrapper">
                                            <div class="table-title">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <h2 class="list_assure">Liste des assurés</h2>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <button type="button" class="btn btn-info add-new c-add-new" (click)="conjChoosen()" [disabled]="beneficiares.length !== 0 && checkAdulte === nombreAdulte && checkEnfant === nombreEnfant">
                                                            <i class="fa fa-plus"></i> Ajouter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr class="c-head">
                                                        <th>Numéro</th>
                                                        <th>Prénom</th>
                                                        <th>Nom</th>
                                                        <th>Type</th>
                                                        <th>Naissance</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let c of beneficiares; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                                        <td>{{i+1}}</td>
                                                        <td>{{c.prenom}}</td>
                                                        <td>{{c.nom}}</td>
                                                        <td>{{c.type}}</td>
                                                        <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                                        <td>
                                                            <a class="add" title="Add" data-toggle="tooltip"><i
                                                                    class="material-icons"></i></a>
                                                            <a class="edit" title="Edit" data-toggle="tooltip" (click)="edit(c,i)"><i class="fa fa-pencil"></i></a>
                                                            <a class="delete" title="Delete" data-toggle="tooltip" (click)="deletePackAssurer(c,i)"><i
                                                                    class="fa fa-trash-o"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p class="text-center" *ngIf="beneficiares.length === 0">la liste des assurés est vide</p>
                                        </div>
                                    </div>
                                </div>


                                <!-- contentResultsign  Ajout Assuré pour santé-->
                                <ng-template #addAssures let-modal>
                                    <form [formGroup]="souscriptionForm">
                                        <div class="row card card0 c-card beneficiary-section form-container " formGroupName="assure" *ngIf="beneficiares.length === 0">
                                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                                                <div class="col-10 p-0">
                                                    <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                                </div>
                                                <div class="col-lg-2 text-right">
                                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right c-close">
                                                        <i class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                                </div>
                                            </div>

                                            <!-- 1er step  -->

                                            <div class="row formatForm" *ngIf="currentStepeur==='assure'">
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
                                                            <option selected disabled>Type</option>
                                                            <option value="1">Pour moi-même</option>
                                                            <option value="2">Pour un tiers</option>
                                                        </select>
                                                        <label for="cars" class="form__label">Type<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                        <i class="fa-solid fa-venus form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                                            <option value="" selected disabled>Sexe</option>
                                                            <option value="M">Masculin</option>
                                                            <option value="F">Féminin</option>
                                                        </select>
                                                        <label for="cars" class="form__label">Sexe<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('genre').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                                        <label for="emai" class="form__label">Prénom<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('prenom').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                                        <label for="email" class="form__label">Nom<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('nom').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-phone form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                                        <label for="email" class="form__label">Numéro de
                                                            téléphone<span class="text-red">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('numeroTelephone').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-envelope form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                                        <label for="email" class="form__label">Email<span
                                                                class="text-red"> </span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('email').invalid">
                                                        Ce champ est obligatoire ou invalide
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-vcard form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                                        <label for="email" class="form__label">Profession<span
                                                                class="text-red"></span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('profession').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-briefcase form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                                        <label for="email" class="form__label">Secteur
                                                            d'activité<span class="text-red">
                                                                </span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('secteuractivite').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-home form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                                        <label for="email" class="form__label">Adresse<span
                                                                class="text-red"> </span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('adresse').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                                        <i class="fa fa-calendar form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker" (click)="dateAssu.toggle()"
                                                        />
                                                        <label for="email" class="form__label">Date de
                                                            naissance<span class="text-red">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('assure').get('dateNaissance').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <button type="button" class="btn  btn-large c-btnAjout" (click)="setAssureSante(2,'assure')" *ngIf="!updateConj"><i
                                                        class="fa fa-plus"></i>
                                                    Suivant</button>
                                                <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)" *ngIf="updateConj"><i
                                                        class="fa fa-pencil"></i>
                                                    Modifier</button>

                                            </div>

                                            <!-- Fin 1er step  -->

                                            <!-- Debut  2eme  step  -->
                                            <div *ngIf="currentStepeur==='questionnaire'">

                                                <div class="table-wrapper row">
                                                    <div class="table-title">
                                                        <table class="table table-bordered">
                                                            <thead class="dispoEntete">
                                                                <tr class="c-headQuestionnaire">
                                                                    <th class="colorNature">Nature</th>
                                                                    <th class="colorNature">Réponse</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let qSante of tableauQuestionnaire?.body; let k=index" class="formatBody" [ngClass]="{'c-tr':k%2 === 1}">
                                                                    <td>
                                                                        {{qSante?.question}}
                                                                        <span class="row" *ngIf="(qSante?.question.includes('Précisez') || qSante?.question.includes('si oui')) && qSante?.reponse === 'oui'">
                                                                            <div class="form" ngbPopover="Précisez"
                                                                                triggers="mouseenter:mouseleave">
                                                                                <input type="text" id="description"
                                                                                    class="form__input"
                                                                                    autocomplete="off"
                                                                                    placeholder=" " />
                                                                                <label for="email"
                                                                                    class="form__label">Précisez</label>
                                                                            </div>
                                                                        </span>
                                                                    </td>
                                                                    <td *ngFor="let item of enteteWithoutAs">
                                                                        <div *ngFor="let rb of reponses; let j=index" class="custom-control custom-radio custom-control-inline">
                                                                            <input (click)="selectQuestion(qSante, $event,rb)" [checked]="qSante.reponse==rb" type="radio" id="{{item.name}}_{{k}}_{{j}}" name="{{item.name}}_{{k}}" class="custom-control-input">
                                                                            <label class="custom-control-label" for="{{item.name}}_{{k}}_{{j}}">{{rb}}</label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('assure')" *ngIf="!updateConj"><i
                                                            class="fa fa-plus"></i>
                                                        Terminer</button>
                                                </div>
                                                <!-- Fin 2eme step  -->


                                            </div>

                                        </div>
                                        <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="beneficiares.length > 0">
                                            <div class="row no-margin">
                                                <!-- <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                                                    <div class="row no-margin">
                                                        <div class="col-10 p-0">
                                                            <h6 class="text-left text-white c-cardTitle">Ajouter assuré4
                                                            </h6>
                                                        </div>
                                                        <div class="col-lg-2 text-right">
                                                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                                                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                                                    <div class="col-10 p-0">
                                                        <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                                    </div>
                                                    <div class="col-lg-2 text-right">
                                                        <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right c-close">
                                                            <i class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" placement="top" ngbPopover="Enfant ou adulte" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-intersex form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" formControlName="type">
                                                            <option value="enfant">
                                                                Enfant
                                                            </option>
                                                            <option value="adulte">
                                                                Adulte
                                                            </option>
                                                        </select>
                                                        <label for="cars" class="form__label">Type<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('type').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                        <i class="fa-solid fa-venus form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                                            <option value="" selected disabled>Sexe</option>
                                                            <option value="M">Masculin</option>
                                                            <option value="F">Féminin</option>
                                                        </select>
                                                        <label for="cars" class="form__label">Sexe<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                                        <label for="email" class="form__label">Prénom<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                                        <label for="email" class="form__label">Nom<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-home form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                                        <label for="adresse" class="form__label">Adresse<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                                        <i class="fa fa-calendar form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="date" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="dateNaissance" />
                                                        <label for="email" class="form__label">Date de
                                                            naissance<span class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-phone form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                                        <label for="email" class="form__label">Numéro de
                                                            téléphone<span class="text-red"
                                                                *ngIf="champsObligatoire == false">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid && champsObligatoire == false">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-envelope form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                                        <label for="email" class="form__label">Email<span
                                                                class="text-red" *ngIf="champsObligatoire == false">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('email').invalid && champsObligatoire == false">
                                                        Ce champ est obligatoire ou invalide
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-user form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                                        <label for="email" class="form__label">Profession<span
                                                                class="text-red" *ngIf="champsObligatoire == false">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid && champsObligatoire == false">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                                    <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                                        <i class="fa fa-briefcase form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                                        <label for="secteuractivite" class="form__label">Secteur
                                                            d'activité<span class="text-red"
                                                                *ngIf="champsObligatoire == false">
                                                                *</span></label>
                                                    </div>
                                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid && champsObligatoire == false">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('beneficiaire')" *ngIf="!updateConj"><i
                                                        class="fa fa-plus"></i>
                                                    Ajouter</button>
                                                <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)" *ngIf="updateConj"><i
                                                        class="fa fa-plus"></i> Modifier</button>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>

                            </div>
                        </div>

                        <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="beneficiaryChoosen">
                            <div class="row no-margin">
                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-right">
                                    <a (click)="beneficiaryChoosen = false" class="text-right"><i
                                            class="fa fa-times text-danger times-ass"></i></a>
                                </div>
                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" ngbPopover="Veuillez selectionner la relation entre vous et le beneficiaire" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-intersex  form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" formControlName="type" (change)="checkType($event)">
                                            <option value="" selected disabled>Relation</option>
                                            <option [value]="r" *ngFor="let r of tabRelation">{{r}}</option>
                                        </select>
                                        <label for="cars" class="form__label">Relation<span class="text-red">
                                                *</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                        <label for="prenom" class="form__label">Prénom<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                        <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="numeroTelephone" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                        <label for="numeroTelephone" class="form__label">Numéro de téléphone<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                        <label for="profession" class="form__label">Profession<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-house form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                        <label for="adresse" class="form__label">Adresse<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker #datebenef="ngbDatepicker"
                                            (click)="datebenef.toggle()" />
                                        <label for="dateNaissance" class="form__label">Date de naissance<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" ngbPopover="Veuillez selectionner  le sexe" triggers="mouseenter:mouseleave" popoverTitle="Sexe">
                                        <i class="fa-solid fa-venus form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                            <option value="" selected disabled>Sexe</option>
                                            <option value="M">Masculin</option>
                                            <option value="F">Féminin</option>
                                        </select>
                                        <label for="cars" class="form__label">Sexe<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                        <i class="fa fa-briefcase form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                        <label for="secteuractivite" class="form__label">Secteur d'activité<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary btn-large btn-primary  col-4 m-auto" (click)="addbenef()" *ngIf="beneficiaryChoosen"><i class="fa fa-plus"></i>
                                    Ajouter</button>
                            </div>

                        </div>
                    </div>
                    <div class="" *ngIf="showDevice">
                        <div class="recap-form-container">
                            <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    <!-- <img src="../../../../assets/img/logo123.png" alt=""> -->
                                    <img [src]="logoImage" alt="logo">
                                </div>
                                <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
                                </div>
                            </div>
                            <div class="info-devis">
                                <div class="proposition">
                                    <div class="pro-title">Santé</div>
                                    <div class="sub-title">{{numeroSous}}
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span>Souscripteur</span>

                                            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(1)"></span> -->
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('prenom')?.value}} {{souscriptionForm?.get('assure')?.get('nom')?.value}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('email')?.value}} </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('numeroTelephone')?.value}}
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('adresse')?.value}} </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('profession')?.value}} </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('secteuractivite')?.value}}
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Assureur</div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                    {{currentAssureur?.nom}} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span>Références Proposition</span>

                                            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(3)"></span> -->
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('prenom')?.value}} {{souscriptionForm.get('assure').get('nom').value}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('assure')?.get('adresse')?.value}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet
                                                </div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{souscriptionForm?.get('contrat')?.get('debut')?.value | date:'dd/MM/yyyy'}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                                </div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{dateExpiration | date:'dd/MM/yyyy'}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{currentAssureur?.dureeDisplay}}</div>
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                                        <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">
                                                            Adulte(s)</div>
                                                        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value">
                                                            {{nombreAdulte}}</div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                                        <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">
                                                            Enfant(s)</div>
                                                        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value">
                                                            {{nombreEnfant}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="beneficiares.length > 0" class="row isForChildren">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="c-head">
                                                <th>Prénom</th>
                                                <th>Nom</th>
                                                <th>Type</th>
                                                <th>Naissance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let c of beneficiares; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                                <td>{{c.prenom}}</td>
                                                <td>{{c.nom}}</td>
                                                <td>{{c.type}}</td>
                                                <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div class="title-dispo-footerL col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                        <!-- Assureur : {{currentAssureur?.nom}} -->
                                    </div>
                                    <div class="title-dispo-footerR col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                        Date emission : {{newDate |date:'dd/MM/yyyy'}} E-MAIL : {{email}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retourDevice()"><i class="fa fa-chevron-left"></i>
                                    Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" (click)="devisNextStep()"> <i class="fa fa-spinner fa-pulse"
                                        *ngIf="sendingRequest"></i>Suivant</button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" *ngIf="!showDevice">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <!-- <div class="col-5" *ngIf="act==true">
                            <button type="button" class="btn btn-success btn-next pull-right c-roundbtn" [disabled]="beneficiares.length === 0 || checkAdulte !== nombreAdulte || checkEnfant !== nombreEnfant" (click)="showDevice = true"> <i class="fa fa-spinner fa-pulse"
                                    *ngIf="sendingRequest"></i>Suivant</button>
                        </div> -->
                        <div class="col-5" *ngIf="act==true">
                            <button type="button" class="btn btn-success btn-next pull-right c-roundbtn" [disabled]="beneficiares.length === 0 || checkAdulte !== nombreAdulte || checkEnfant !== nombreEnfant" (click)="devisNextStep()"> <i class="fa fa-spinner fa-pulse"
                                    *ngIf="sendingRequest"></i>Suivant</button>
                        </div>
                        <!-- <div class="col-5" *ngIf="act==false">
                            <button [disabled]="beneficiares.length === 0" type="button" class="btn btn-success btn-next pull-right c-roundbtn" (click)="showDevice = true"> <i
                                    class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Voir devis</button>
                        </div> -->

                        <div class="col-5" *ngIf="act==false">
                            <button [disabled]="beneficiares.length === 0" type="button" class="btn btn-success btn-next pull-right c-roundbtn" (click)="devisNextStep()"> <i
                                    class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Suivant</button>
                        </div>

                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Récapitulatif" *ngIf="!choose">
                    <div class="recap-form-container">
                        <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <!-- <img src="../../../../assets/img/logo123.png" alt=""> -->
                                <img [src]="logoImage" alt="logo">
                            </div>
                            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title">Sante</div>
                                <div class="sub-title">{{numeroSous}}
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Souscripteur</span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(2)"></span> -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('prenom')?.value}} {{souscriptionForm.get('assure').get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-6 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('email')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('numeroTelephone')?.value}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('profession')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('secteuractivite')?.value}}
                                            </div>
                                            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Assureur</div> -->
                                            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{currentAssureur?.nom}} </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(2)"></span> -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('prenom')?.value}} {{souscriptionForm?.get('assure')?.get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{souscriptionForm?.get('contrat')?.get('debut')?.value | date:'dd/MM/yyyy'}} </div>

                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                            </div> -->
                                            <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}}
                                            </div> -->

                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">DuréeS</div> -->
                                            <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{currentAssureur?.dureeDisplay}}</div> -->
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                                    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte</div>
                                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value">
                                                        {{checkAdulte}}</div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                                    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant</div>
                                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value">
                                                        {{checkEnfant}}</div>
                                                </div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="beneficiares.length > 0" class="row isForChildren">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="c-head">
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            <th>Type</th>
                                            <th>Naissance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let c of beneficiares; let i=index" [ngClass]="{'c-tr':i%2 === 1}">
                                            <td>{{c.prenom}}</td>
                                            <td>{{c.nom}}</td>
                                            <td>{{c.type}}</td>
                                            <td>{{c.dateNaissance | date: 'dd-MM-yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div class="title-dispo-footerL col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                    <!-- Assureur : {{currentAssureur?.nom}} -->
                                </div>
                                <div class="title-dispo-footerR col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                    Date emission : {{newDate |date:'dd/MM/yyyy'}} E-MAIL : {{email}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscription" [disabled]="sendingRequest"> <i
                                    class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>


            </aw-wizard>
        </form>
    </div>
</div>


<section class="find-agent-area-sante ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="avantage">Avantages</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon height-icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>
                                <h6 class="titre_card">La prise en charge des médecines douces et alternatives</h6>
                                <p>Encore un avantage essentiel offert par une bonne mutuelle : la prise en charge des médecines douces et thérapies alternatives.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon height-icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>
                                <h6 class="titre_card">Des formules flexibles pour répondre à tous vos besoins</h6>
                                <p>L’avantage d’une mutuelle est au final sa flexibilité. Contrairement à la Sécurité sociale.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon height-icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>
                                <h6 class="titre_card">Le remboursement complémentaire des soins courants</h6>
                                <p>La Sécurité sociale, obligatoire, rembourse une bonne part de vos frais de santé les plus courants : consultations médicales, examens, analyses.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon height-icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>
                                <h6 class="titre_card">Pouvoir profiter du 100 % santé en optique</h6>
                                <p>Ces trois postes de dépenses s’avèrent en effet très coûteux, et n’étaient jusqu'à récemment que peu couverts par la Sécurité sociale.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="modal-body login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span
                                                class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span
                                                class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp" (click)="lanceforgot()">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}
                                    </div>
                                    <div class="text-center"><button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3 margeTop">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>



<ng-template #simulateurDevis let-modal>
    <div class="modal-body p-5 bg-white download" #santeDownload id="santeDownload">
        <div class="row">
            <div class="col-10 mesImage">
                <div class="imgLogo">
                    <img [src]="listAssureurs[0].img" alt="">
                </div>
            </div>
            <div class="col-lg-2 text-right">
            </div>
        </div>
        <div class="info-devis">
            <div class="proposition">
                <div class="pro-title"> Proposition Sante</div>
                <div class="sub-title">(Cette proposition est valable pour une année )
                </div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Références du souscripteur
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('prenom').value}} {{souscriptionForm.get('assure').get('nom').value}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('email').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('adresse').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('profession').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type formule</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{dataSimulateur.typeFormule}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Formule</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{dataSimulateur.formule}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an(s)
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adulte(s)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{checkAdulte}}</div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Enfant(s)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{checkEnfant}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        <span> Identification du produit </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.formule}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Type formule</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.typeFormule}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Service</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.service}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.age}} an(s)
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Cout piéce</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.coutPiece | number:'1.0-0':'fr'}} Fcfa
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Taxe</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.taxe | number:'1.0-0':'fr'}} Fcfa
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime Nette</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{dataSimulateur.primeNette | number:'1.0-0':'fr'}} Fcfa
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prime TTC</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{dataSimulateur.primeTtc | number:'1.0-0':'fr'}} Fcfa
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{dataSimulateur.primeTtc | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Majoration De Prix </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                                Personne agée
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="dataSimulateur.enfantSupp">
                                Enfant(s) Supplementaire
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="dataSimulateur.adulteSupp">
                                Adulte(s) Supplementaire
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Nombres
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                                {{dataSimulateur.ageMajor}}
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" *ngIf="dataSimulateur.enfantSupp > 0">
                                {{dataSimulateur.enfantSupp}}
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center" *ngIf="dataSimulateur.adulteSupp > 0">
                                {{dataSimulateur.adulteSupp}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Plafond (FCFA)
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" *ngIf="dataSimulateur.ageMajor > 0 && souscriptionForm.get('AxASimulateur').get('isOld').value==='0'">
                                {{dataSimulateur?.majoration | number:'1.0-0':'fr'}}
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" *ngIf="dataSimulateur.enfantSupp > 0">
                                {{dataSimulateur?.enfantSuppValTTc | number:'1.0-0':'fr'}}
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right" *ngIf="dataSimulateur.adulteSupp > 0">
                                {{dataSimulateur?.adulteSuppValTTc | number:'1.0-0':'fr'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="disposition">
                <div class="title-disp">
                    Dispositions complémentaires
                </div>
                <div class="desc-disp">
                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                </div>
            </div>
        </div>
        <div class="footer-devis row">
            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div class="fist-line">
                    <b class="name-devis">{{currentAssureur?.nom}}</b> est une <b>{{currentAssureur?.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur?.capital) |
                        number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                    <b>{{currentAssureur?.ninea}}</b>-RC: <b>{{currentAssureur?.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à <b>{{currentAssureur?.adresse}}</b>.
                </div>
                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur?.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                    <b>{{currentAssureur?.email}}</b>
                </div>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                <img class="footer-img" [src]="assureurSelected.img" alt="">
            </div>
        </div>

    </div>
    <div>
        <div class="row">
            <div class="col-4">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" (click)="modal.close('close')"><i class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-4 text-center">
                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()" placement="top" ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave" popoverTitle="Devis">Télécharger <i class="fa fa-download"></i></button>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')" (click)="validerSiulateur1()">
                    Souscrire <i class="fa fa-angle-double-right"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentResultass let-modal>
    <div class="modal-body p-5 bg-white download" #santeDownload id="santeDownload">

        <div class="mesImage">
            <div class="imgLogo">
                <img [src]="currentAssureur.img" alt="">
            </div>
        </div>
        <div class="info-devis">
            <div class="proposition">
                <div class="pro-title"> Proposition Sante</div>
                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                </div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Références du souscripteur
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('prenom').value}} {{souscriptionForm.get('assure').get('nom').value}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('email').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('numeroTelephone').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('adresse').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('profession').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('assure').get('secteuractivite').value}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user.infos.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{souscriptionForm.get('contrat').get('debut').value |date:'dd/MM/yyyy'}}
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}}
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.dureeDisplay}}
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Adulte</div>
                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{conjoint}}</div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 row">
                                    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name">Enfant</div>
                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value"> {{enfant}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        <span> Identification du produit </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Formule</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{currentPack.formule}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Produit</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{currentPack.produit}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{ currentPack.age }} an(s)
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Prix</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{currentPack.prix |number:'1.0-0':'fr'}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Durée</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{currentAssureur?.dureeDisplay}}
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proposition2">
                <div class="pro-title"> Avantages </div>
            </div>
            <div class="row c-row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        <span> Description </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.avantages">
                                {{item.nom}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{currentPack.prix |number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Nom </span>
                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="goTo(0)"></span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let item of currentPack.prestations">
                                {{item.nom}}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Plafond (FCFA)
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                                {{item.plafond |number:'1.0-0':'fr'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Fréquence
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let item of currentPack.prestations">
                                {{item.frequence}}
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div class="disposition">
                <div class="title-disp">
                    Dispositions complémentaires
                </div>
                <div class="desc-disp">
                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                </div>
            </div>
        </div>
        <div class="footer-devis row">
            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div class="fist-line">
                    <b class="name-devis">{{currentAssureur?.nom}}</b> est une <b>{{currentAssureur?.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur?.capital) |
                        number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                    <b>{{currentAssureur?.ninea}}</b>-RC: <b>{{currentAssureur?.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à <b>{{currentAssureur?.adresse}}</b>.
                </div>
                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur?.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                    <b>{{currentAssureur?.email}}</b>
                </div>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                <img class="footer-img" [src]="currentAssureur.img" alt="">
            </div>
        </div>

    </div>
    <div>
        <div class="row">
            <div class="col-4">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left" (click)="modal.close('close')"><i class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-4 text-center">
                <button type="button" class="btn btn-primary btn-tel" (click)="captureScreen()" placement="top" ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave" popoverTitle="Devis">Télécharger
                    <i class="fa fa-download"></i></button>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-right" (click)="modal.close('subscribe')">
                    Souscrire <i class="fa fa-angle-double-right"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Nom</label>
                                        <input type="text" id="nom" placeholder="" class="form-control" formControlName="nom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Prénom
                                        </label> <input type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                            mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro
                                            téléphone</label> <input type="text" id="telephone" placeholder="" class="form-control" formControlName="telephone" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('telephone')?.errors?.required && (inscriptionForm.get('telephone')?.touched || inscriptionForm.get('telephone')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- <ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                            mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="forgotForm.get('email')?.errors?.required && (forgotForm.get('email')?.touched || forgotForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="forgotForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>

                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->


<ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div> -->
                                    <h6 class="msg-info text-center">Mot de passe oublié ?</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #contentResultResetPassword let-modal>
    <div class="modal-body login">
        <form [formGroup]="resetPasswordForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Code OTP</label>
                                        <input type="text" id="token" placeholder="" class="form-control" formControlName="token">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('token')?.errors?.required && (resetPasswordForm?.get('token')?.touched || resetPasswordForm.get('token')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe
                                        </label> <input type="text" id="newPassword" placeholder="" class="form-control" formControlName="newPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('newPassword')?.errors?.required && (resetPasswordForm.get('newPassword')?.touched || resetPasswordForm.get('newPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="form-group"> <label class="form-control-label text-muted">Confirmation de mot de passe
                                    </label> <input type="text" id="confirmPassword" placeholder="" class="form-control" formControlName="confirmPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.required && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="resetPassword()" [disabled]="loaderVisible">Changez votre mot de passe</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Changez de mot de passe en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- Modal Prestation -->
<ng-template #prestation let-modal>
    <div class="modal-header c-modal-header">
        <h5 class="modal-title" id="modal-basic-title"> {{currentTitle}} </h5>
        <button type="button" class="close close-but" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body c-modal-body">
        <table class="table table-stripped c-pres-table" *ngIf="currentTitle=='Liste des prestations'">
            <thead>
                <tr>
                    <th>Nature</th>
                    <th>Taux de couverture</th>
                    <th>Plafond(FCFA)</th>
                    <th>Durée</th>
                </tr>
            </thead>
            <tbody *ngIf="prestations.length>0">
                <tr *ngFor="let item of prestations">
                    <td> {{item.nom}} </td>
                    <td> {{item.taux}} </td>
                    <td> {{item.plafond | number:"1.0-0":"fr"}} </td>
                    <td> {{item.frequence}} </td>
                </tr>
            </tbody>
            <div class="vide" *ngIf="prestations.length==0">Aucun prestataire pour ce pack</div>
        </table>
        <table class="table table-stripped c-pres-table" *ngIf="currentTitle=='Liste des avantages'">
            <thead>
                <tr>
                    <th>Libellé</th>
                </tr>
            </thead>
            <tbody *ngIf="prestations.length>0">
                <tr *ngFor="let item of prestations">
                    <td> {{item.nom}} </td>
                </tr>
            </tbody>
            <div class="vide" *ngIf="prestations.length==0">Aucun prestataire pour ce pack</div>
        </table>
    </div>
</ng-template>


<!--   Modal    prestataire -->
<ng-template #prestataire let-modal>
    <div class="modal-header c-modal-header">
        <h4 class="modal-title" id="modal-basic-title">Liste des prestataires</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body c-modal-body">
        <table class="table table-stripped c-pres-table">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Téléphone</th>
                    <th>Adresse</th>
                    <th>Lieu</th>
                    <th>Spécialité</th>
                </tr>
            </thead>
            <tbody *ngIf="prestataires.length>0">
                <tr *ngFor="let item of prestataires">
                    <td> {{item.nom}} </td>
                    <td> {{item.telephone }} </td>
                    <td> {{item.localite}} </td>
                    <td> {{item.adresse}} </td>
                    <td> {{item.specialite}} </td>
                </tr>
            </tbody>
            <div class="vide" *ngIf="prestataires.length==0">Aucun prestataire pour ce pack</div>
        </table>

    </div>
</ng-template>


<!-- Modal pour questionnaire Assurance Santé -->

<ng-template #questionnaireSante let-modal>
    <div class="card c-card">
        <div class="c-headModal">

            <h6 class="text-left text-white c-cardTitle">Questionnaire Santé</h6>

            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right c-close"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>

        </div>

        <div class="table-wrapper row">
            <div class="table-title">
                <table class="table table-bordered">
                    <thead class="dispoEntete">
                        <tr class="c-headQuestionnaire">
                            <th *ngFor="let entete of tableauQuestionnaire.entete; let i=index">{{entete?.name}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let qSante of tableauQuestionnaire.body; let k=index" class="formatBody" [ngClass]="{'c-tr':k%2 === 1}">
                            <td>{{qSante?.question}}</td>
                            <td *ngFor="let item of enteteWithout">
                                <div *ngFor="let rb of qSante[item.name]; let j=index" class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="{{item.name}}_{{k}}_{{j}}" name="{{item.name}}_{{k}}" class="custom-control-input">
                                    <label class="custom-control-label" for="{{item.name}}_{{k}}_{{j}}">{{rb}}</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('assure')" *ngIf="!updateConj"><i
                class="fa fa-plus"></i> Ajouter</button>
        <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)" *ngIf="updateConj"><i class="fa fa-pencil"></i> Modifier</button>
        <div class="row beneficiary-section form-container " formGroupName="beneficiaire" *ngIf="beneficiares.length > 0">
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Enfant ou adulte" triggers="mouseenter:mouseleave">
                        <i class="fa fa-intersex form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)" formControlName="type">
                            <option selected>Type</option>
                            <option value="enfant" *ngIf="checkEnfant < nombreEnfant">Enfant</option>
                            <option value="adulte" *ngIf="checkAdulte < nombreAdulte">Adulte</option>
                        </select>
                        <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('type').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                        <i class="fa-solid fa-venus form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                            <option value="" selected disabled>Sexe</option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                        </select>
                        <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('genre').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">

                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                        <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('prenom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                        <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('nom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                        <i class="fa fa-home form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                        <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('adresse').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                        <i class="fa fa-calendar form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect($event, 'beneficiaire','dateNaissance')" ngbDatepicker #datebenef="ngbDatepicker"
                            (click)="datebenef.toggle()" />
                        <label for="email" class="form__label">Date de naissance<span class="text-red">
                                *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('dateNaissance').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                        <i class="fa fa-phone form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                        <label for="email" class="form__label">Numéro de téléphone<span class="text-red"
                                *ngIf="champsObligatoire == false"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('numeroTelephone').invalid && champsObligatoire == false">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                        <i class="fa fa-envelope form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                        <label for="email" class="form__label">Email<span class="text-red"
                                *ngIf="champsObligatoire == false"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('email').invalid && champsObligatoire == false">
                        Ce champ est obligatoire ou invalide
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                        <label for="email" class="form__label">Profession<span class="text-red"
                                *ngIf="champsObligatoire == false"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('profession').invalid && champsObligatoire == false">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                        <i class="fa fa-briefcase form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                        <label for="secteuractivite" class="form__label">Secteur d'activité<span class="text-red"
                                *ngIf="champsObligatoire == false"> *</span></label>
                    </div>
                    <div class="error  text-red" *ngIf="invalid && souscriptionForm.get('beneficiaire').get('secteuractivite').invalid && champsObligatoire == false">
                        Ce champ est obligatoire
                    </div>
                </div>
                <button type="button" class="btn  btn-large c-btnAjout" (click)="addPackAssure('beneficiaire')" *ngIf="!updateConj"><i class="fa fa-plus"></i> Ajouter</button>
                <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(beneficiares)" *ngIf="updateConj"><i class="fa fa-plus"></i> Modifier</button>
            </div>
        </div>
    </div>
</ng-template>


<!--start remonter plainte modal-->
<ng-template #plainte let-modal>
    <form [formGroup]="plainteForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card2 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Remonter une plainte</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                <label for="emai" class="form__label">Nom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('nom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                <label for="prenom" class="form__label">Prénom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('prenom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                <label for="emai" class="form__label">Email<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('email').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="telephone" class="form__input" autocomplete="off" placeholder="Téléphone" formControlName="telephone" />
                                <label for="telephone" class="form__label">Téléphone<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('telephone').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prestataire du réseau de soin" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prestation" class="form__input" autocomplete="off" placeholder="Prestataire du réseau de soin" formControlName="prestation" />
                                <label for="prestation" class="form__label">Prestataire du réseau de soin<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('prestation').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Entreprise" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="nomEntreprise" class="form__input" autocomplete="off" placeholder="Entreprise" formControlName="nomEntreprise" />
                                <label for="nomEntreprise" class="form__label">Entreprise<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('nomEntreprise').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Type plainte" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="typePlainte" placeholder="Type plainte" class="form__select" formControlName="typePlainte" name="typePlainte" #typePlainte>
                                    <option value="" disabled selected>Type plainte</option>
                                    <option *ngFor="let plainte of typePlainteList" [value]="plainte.value">{{plainte?.name}}
                                    </option>
                                </select>
                                <label for="typePlainte" class="form__label">Type plainte<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && plainteForm.get('typePlainte').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="plainteForm.get('typePlainte').value == 'Autres'">
                            <div class="form" ngbPopover="Nature plainte" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="naturePlainte" class="form__input" autocomplete="off" placeholder="Nature plainte" formControlName="naturePlainte" />
                                <label for="naturePlainte" class="form__label">Nature plainte<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && plainteForm.get('naturePlainte').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Autres précisions" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="textarea" min="0" id="description" class="form__input textarea" autocomplete="off" formControlName="description" placeholder="Autres précisions" />
                                <label for="description" class="form__label">Autres précisions</label>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="remonterPlainte()" [disabled]="sendingRequestPlainte">
                  <i class="fa fa-pencil" *ngIf="!sendingRequestPlainte"></i> 
                  <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequestPlainte"></i>
                  Valider
                </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<!--end remonter plainte modal-->


<swal #confirmSubscription title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [reverseButtons]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<swal #confirmSubscriptionSimulation title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [reverseButtons]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveDataSimulation()">
</swal>








<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>

<swal #confirmSubscription title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>
<swal #confirmSubscriptionSimulation title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<ng-template #mypop>
    1.Pour moi-même : mon nom est sur la carte à grise
    <br>2.Pour un tiers :pour une tierce personne
    <br>3.Pour le compte de :c'est ma voiture mais mon nom n'est pas sur la carte à grise
</ng-template>