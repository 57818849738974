import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  localisation = localStorage.getItem('localisation');
  telephone = localStorage.getItem('telephone');
  email = localStorage.getItem('email');
  constructor() { }

  ngOnInit(): void {
  }

}
