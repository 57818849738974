<!-- <app-header-four></app-header-four> -->
<app-header-one></app-header-one>
<div class="main-banner-section height_image item-bg166">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>ASSURANCE VOYAGE</h1>
                    <p> L’assurance voyage est un contrat qui vous couvre en cas d’imprévu pendant votre séjour à l’étranger. Perte de vos bagages, accident, maladie, dommages causés à autrui… Autant d’événements qui pourraient gâcher votre séjour.
                    </p>

                    <div class="btn-box">
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                        <a class="optional-btn rappel" (click)="rappeler()">Me faire rappeler<span></span></a>
                    </div>
                    <!-- <div class="btn-box" *ngIf="login">
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                        <a class="optional-btn rappel">Me faire rappeler<span></span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>

<div class="row desc container-row justify-content-center" id="contact" #contact>
    <div class="col-12 droite" *ngIf="login">
        <form [formGroup]="simulationVoyageForm" class="auto">
            <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Voyage">
                    <div class="form-container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="left" ngbPopover="Pays de départ" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-flag form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " formControlName="paysDepart" class="form__select" (change)="setAssure($event)" name="pays">
                          <option value="" disabled selected>Pays de départ</option>
                          <option *ngFor="let pays of listPays" [value]="pays.nom">{{pays?.nom}}</option>
                        </select>
                                    <label for="cars" class="form__label">Pays de départ<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('paysDepart').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="left" ngbPopover="Pays d'arrivée" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-flag form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" formControlName="paysArrive" placeholder=" " class="form__select" (change)="setAssure($event)">
                          <option value="" disabled selected>Pays d'arrivée</option>
                          <option *ngFor="let pays of listPays" [value]="pays.nom">{{pays?.nom}}</option>
                        </select>
                                    <label for="cars" class="form__label">Pays d'arrivée<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('paysArrive').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Date de départ" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-plane-up form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateDepart" class="form__input" placeholder=" " (dateSelect)="onDateSelect($event,'dateDepart')" ngbDatepicker #dateDepart="ngbDatepicker" (click)="dateDepart.toggle()" />
                                    <label for="dateDepart" class="form__label">Date de départ<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('dateDepart').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Date de retour" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-plane-arrival form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateRetour" class="form__input" autocomplete="off" placeholder=" " [minDate]="minDate" (dateSelect)="onDateSelect2($event,'dateRetour')" ngbDatepicker #dateRetour="ngbDatepicker" (click)="dateRetour.toggle()" />
                                    <label for="dateRetour" class="form__label">Date de retour<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('dateRetour').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Date de naissance" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-calendar-days form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " [minDate]="maxDateNaissance" (dateSelect)="onDateSelect($event,'dateNaissance')" ngbDatepicker #dateNaissance="ngbDatepicker" (click)="dateNaissance.toggle()" />
                                    <label for="email" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i
                        class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assureur">
                    <div class="container">
                        <h6 *ngIf="!sendingRequestAssureur && assureurs.length !== 0" class="VeuFont">Veuillez choisir votre assureur pour obtenir votre devis</h6>
                        <h6 *ngIf="!sendingRequestAssureur && assureurs.length == 0" class="text-center">Désolé y'a pas d'assureur disponible pour votre choix !!!</h6>
                        <div class="justify-content-center">

                            <div class="noAssureur" *ngIf="sendingRequestAssureur">
                                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                <p class="text-center text-ass">Chargement en cours...</p>
                            </div>
                            <section class="pricing-area ptb-70 pb-70">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of assureurs">
                                            <div class="single-pricing-box">
                                                <div class="pricing-header bg-{{res?.code}}">
                                                </div>
                                                <div class="c-price">
                                                    <sub>{{res?.prix |number:'1.0-0':'fr'}} &nbsp;
                                                FCFA</sub>
                                                </div>
                                                <div class="book-now-btn" (click)="nextstep()">
                                                    <span class="default-btn">Souscrire en ligne</span>
                                                </div>
                                                <ul class="pricing-features-list">
                                                    <li class="tehgr" (click)="openModalEnvoyeDevis(res)">Etre rappelé</li>
                                                    <li class="tehgr" (click)="getprix(res)">Télécharger</li>
                                                    <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li>
                                                    <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="noAssureur" *ngIf="sendingRequest">
                            <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                            <p class="text-center text-ass">Chargement des assureurs en cours...</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                    </div>
                </aw-wizard-step> -->
                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Assuré">
                    <div class="form-container" formGroupName="assure">
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                <i class="fa fa-intersex form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVoyageForm.get('assure').get('choix').value)">
                            <option selected value="1">Pour moi-même</option>
                            <option value="2">Pour un tiers</option>
                            <option value="3">Pour le compte de</option>
                        </select>
                                <label for="cars" class="form__label">Veuillez choisir<span class="text-red">
                                *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('choix').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                    <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                    <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                    <label for="email" class="form__label">Numéro de téléphone<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-vcard form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                    <label for="email" class="form__label">Profession<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-vcard form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                    <label for="email" class="form__label">Activité<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('secteuractivite').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                    <label for="email" class="form__label">Adresse<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" formControlName="genre" placeholder=" " class="form__select">
                          <option value="" selected disabled>Sexe</option>
                          <option value="M">Masculin</option>
                          <option value="F">Féminin</option>
                        </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()" awPreviousStep><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">Suivant</button>
                        </div>
                    </div>

                </aw-wizard-step>
                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVoyageForm.get('livraison').value == 'Domicile'">
                            <img class="c-moyenPaiementimg" src="assets/images/domicile.jpg" alt="">
                            <div class="badge">
                                Domicile
                            </div>
                        </div>
                        <div class="mt-4 p-0 badge-content" (click)="relais()" class="badge" [class.selected]="simulationVoyageForm.get('livraison').value == 'Relais'">
                            <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
                        <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                            <div class="badge" [class.selected]="simulationVoyageForm.get('moyenDePaiement').value == pay.code">
                                {{pay?.nom}}
                            </div>
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right" (click)="retour()" awPreviousStep><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left" awNextStep>Suivant <i
                        class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>

                </aw-wizard-step> -->
                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                    <section class="team-area ptb-50 pb-50">
                        <div class="row mb-2 justify-content-center">
                            <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        </div>
                        <div class="container mt-5">
                            <div class="row c-cards">
                                <div class="col-md-3 cardB" (click)="domicile()">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVoyageForm.get('livraison').value == 'Domicile'"></i>
                                            </div>
                                        </div> <img src="assets/images/domicile.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">Domicile</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 cardB" (click)="relais()">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVoyageForm.get('livraison').value == 'Relais'"></i>
                                            </div>
                                        </div> <img src="assets/images/relais.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">Point relais</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                            <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                          <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                          <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                      </select>
                                <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                              *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && simulationVoyageForm.get('pointRelaisControl').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                            <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                  class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVoyageForm.get('adresseLivraison').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </section>
                    <div class="row mb-2 justify-content-center">
                        <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
                    </div>
                    <section class="team-area ptb-50 pb-50">

                        <div class="container mt-5">
                            <div class="row">
                                <div class="col-md-3 cardB" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVoyageForm.get('moyenDePaiement').value == pay.code"></i>
                                            </div>
                                        </div> <img [src]="pay.logo" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">{{pay?.nom}}</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                        class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step> -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Pièce Jointe">
                    <div class="container">
                        <div class="row col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow" *ngIf="!downloadFile">
                            <div title="Cliquer pour charger la Pièce Jointe" class="image c-image bg-card" (click)="PIECE.click()">
                                <img src="{{noImage}}" alt="" class="myImg">
                                <div class="titleImage">Pièce Jointe</div>
                                <input [disabled]="loader" hidden #PIECE multiple class="use-avatar" (change)="selectOnFile($event,'PIECE','PIECE')" type="file" id="file" required accept=".doc , .DOC , .docx , .DOCX , .docm , .DOCM" />
                            </div>
                            <div class="chargement" *ngIf="loader">
                                <i class="text-center fa fa-spinner fa-pulse"></i> Chargement du fichier.....
                            </div>
                        </div>
                        <div class="row col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow" *ngIf="downloadFile">
                            <div title="Cliquer pour Télecharger le questionnaire" class="image c-image bg-card">
                                <a (click)="downloadModel()" href="/assets/images/Questionnaire_MRP_Abidjanaise.doc">
                                    <img src="{{downloadImage}}" alt="" class="myImg">
                                </a>
                                <div class="titleImage">Télecharger le questionnaire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Récapitulatif">
                    <div class="recap-form-container">
                        <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <!-- <img src="../../../../assets/img/logo123.png" alt=""> -->
                                <img [src]="logoImage" alt="logo">
                            </div>
                            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Proposition Voyage</div>
                                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références du souscripteur</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave" (click)="goTo(1)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('prenom').value}} {{simulationVoyageForm.get('assure').get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('email').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('numeroTelephone').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('adresse').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('profession').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm?.get('assure')?.get('secteuractivite')?.value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intermédiare</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(3)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('prenom').value}} {{simulationVoyageForm.get('assure').get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('assure').get('adresse').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy'}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an</div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        <span> Identification du voyage </span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de la maison" triggers="mouseenter:mouseleave" (click)="goTo(0)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays de départ</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{simulationVoyageForm.get('paysDepart').value}}
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de départ</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy':'fr'}} </div>
                                                    <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div> -->
                                                    <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{age}} an(s)</div> -->

                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays d'arrivée</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{simulationVoyageForm.get('paysArrive').value}}
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de retour</div>
                                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                        {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy':'fr'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)</div>
                            </div> -->
                            <!-- <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Nature des garanties </span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des garanties" triggers="mouseenter:mouseleave" (click)="goTo(1)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                Frais médicaux et hospitalisation
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                Assistance et rapatriement
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                Responsabilité civile à l’étranger
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Primes d’assurance
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                                Gratuit
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                                Gratuit
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                                Gratuit
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                        {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div> -->
                            <!-- <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of resultSimulation">
                                                    <span *ngIf="item.key!='Montant TTC'">{{item.key}}</span>
                                                    <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.key}}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="val" *ngFor="let item of resultSimulation">
                                                    <span *ngIf="item.key!='Montant TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                                                    <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.value |
                                  number:'1.0-0':'fr'}} FCFA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="disposition row">
                                <div class="title-dispo-footerL">
                                    <!-- Assureur : {{currentAssureur?.nom}} -->
                                </div>
                                <div class="title-dispo-footerR">
                                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : {{email}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()" awPreviousStep><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse"
                        *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>
            </aw-wizard>
        </form>
    </div>
</div>



<section class="find-agent-area-voyage ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="avantage">Avantages</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3 class="titre_card">Assistance rapatriement</h3>
                                <p>très utile si vous ne pouvez pas être traité sur place. La compagnie d’assurance organise votre rapatriement vers votre pays d’origine.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3 class="titre_card">Assurance en cas d’annulation du voyage </h3>
                                <p>Cette garantie vous permettra de ne pas perdre l’argent que vous auriez investi dans l’organisation de votre voyage.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3 class="titre_card">Remboursement de frais</h3>
                                <p>Remboursement de frais médicaux en cas d’accident ou de maladie qui ne requiert pas d’une hospitalisation.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3 class="titre_card">Assurance bagages</h3>
                                <p>comme son nom l’indique, cette garantie vous permettra de ne pas perdre vos bagages en cas de vol de ceux-ci.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp" (click)="lanceforgot()">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div class="text-center"> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3 margeTop">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #contentResult let-modal>
    <div class="modal-body border border-none">
        <div class="cotainer devis-container bg-white" id="htmlData">
            <div class="row row-devis">
                <div class="col-12">
                    <div class="row row-title-devis align-items-center">
                        <div class="col-6 img-logo">
                            <img src="../../../../assets/images/logo6.png" alt="">
                        </div>
                        <div class="col-6 ">
                            <div class="row Informations">
                                <div class="col-12">
                                    <p>Tel: {{telephone}}</p>
                                </div>
                                <div class="col-12">
                                    <p>Email: {{email}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-subtitle-devis ">
                        <div class="col-3 justify-content-center bg-primary">
                            <p class="devis-text">Devis</p>
                        </div>
                        <div class="col-9">
                            <div>
                                <div class="row assure">
                                    <div class="col-12 assure-title">
                                        Voyage
                                    </div>
                                    <div class="col-12 assure-text" *ngFor="let res of resultSimulation">
                                        <div class="row align-items-center" *ngIf="res?.key != 'Prime Totale TTC'">
                                            <div class="col-8">
                                                <p>{{res?.key}}</p>
                                            </div>
                                            <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let res of resultSimulation">
                                <div class="row assure" *ngIf="res?.key == 'Prime Totale TTC'">
                                    <div class="col-12 assure-title">
                                        Total
                                    </div>
                                    <div class="col-12 assure-text">
                                        <div class="row align-items-center ">
                                            <div class="col-8">
                                                <p>{{res?.key}}</p>
                                            </div>
                                            <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
        <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
    </div>

</ng-template>
<ng-template #contentResultgrise let-modal>
    <div class="modal-body">
        <div class="cotainer p-2" id="htmlData">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('close')">Fermer</button>
        <button type="button" class="btn btn-success" (click)="modal.close('subscribe')">Souscrire</button>
    </div>
</ng-template>
<ng-template #contentResult2 let-modal>
    <div class="modal-body">
        <div class="card mb-3 c-card-body">
            <div class="row g-0">
                <div class="col-md-12 p-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row  align-items-center">
                                    <h5 class="text-center col-12 mb-5">Carte grise</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-danger  m-auto c-btn-close" (click)="modal.close('close')">Revenir</button>
    <button class="btn btn-success m-auto c-btn-close" (click)="modal.close('subscribe')">Continuer</button>
</ng-template>
<ng-template #contentResultcontact let-modal>
    <div class="c-modal-header" id="modal-header DKG">
        <h5 class="modal-title text-white" id="modal-basic-title" id="modal-title">Se faire contacter</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-white" id="modal-body">
        <form [formGroup]="personcontactForm">
            <div class="form-container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                            <i class="fa fa-user-circle-o form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="first_name" />
                            <label for="email" class="form__label">Prénom<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('first_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                            <i class="fa fa-user-circle-o form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="last_name" />
                            <label for="email" class="form__label">Nom<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('last_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                            <i class="fa fa-phone form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="telephone" />
                            <label for="email" class="form__label">Téléphone<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('telephone')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                            <i class="fa fa-envelope form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                            <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm.get('email').hasError('pattern')">
                            Ce champ est invalide
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Jour disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <ng-select multiple="true" class="select-rappel" placeholder="Jour à être rappeler" formControlName="jour" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let jour of listJours" [value]="jour.name"> {{jour.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('jour')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Heure disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <ng-select multiple="true" class="select-rappel" placeholder="Heure à être rappeler" formControlName="heure" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let heure of listHeures" [value]="heure.name"> {{heure.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('heure')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-danger" #closeBTN (click)="modal.close('close')">Fermer</button>
            <button type="button" class="btn btn-success" [disabled]="loadContact" (click)="saveContact()"> <span
            *ngIf="loadContact" class="fa fa-spinner fa-pulse"></span> Envoyer</button>
        </form>
    </div>
</ng-template>
<ng-template #contentResultass let-modal>
    <div class="modal-body p-5 bg-white  autodown" #autoDownload id="autoDownload">
        <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <!-- <img src="../../../../assets/img/logo123.png" alt=""> -->
                <img [src]="logoImage" alt="logo">
            </div>
            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
            </div>
        </div>
        <div class="info-devis">
            <div class="proposition">
                <div class="pro-title"> Proposition Voyage</div>
                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}})
                </div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Références du souscripteur
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.profession}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.secteuractivite}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy'}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.firstName}} {{user.infos.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy'}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy'}}
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        <span> Identification du voyage </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays de départ</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{simulationVoyageForm.get('paysDepart').value}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de départ</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{simulationVoyageForm.get('dateDepart').value | date:'dd/MM/yyyy':'fr'}} </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Age</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{age}} an(s)</div>

                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Pays d'arrivée</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{simulationVoyageForm.get('paysArrive').value}} </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Date de retour</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{simulationVoyageForm.get('dateRetour').value | date:'dd/MM/yyyy':'fr'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proposition2">
                <div class="pro-title"> Garanties Souscrites et Primes d’assurance (en FCFA)</div>
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Nature des garanties </span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Frais médicaux et hospitalisation
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Assistance et rapatriement
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Responsabilité civile à l’étranger
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Primes d’assurance
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
            {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div *ngFor="let item of resultSimulation">
                                    <span *ngIf="item.key!='Montant TTC'">{{item.key}}</span>
                                    <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.key}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="val" *ngFor="let item of resultSimulation">
                                    <span *ngIf="item.key!='Montant TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                                    <span *ngIf="item.key=='Montant TTC'" class="valPrime">{{item.value |
                      number:'1.0-0':'fr'}} FCFA</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="disposition">
          <div class="title-disp">
            Dispositions complémentaires
          </div>
          <div class="desc-disp">
            La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
            disposition de
            l'article 13
            <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
              générales
              jointes, les conditions particulières ainsi que les closes en annexe.</div>
            <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
              disposition des conditions générales (CG) qui leurs sont contraires.</div>
            <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
              visas de
              l'assureur et du souscripteur. </div>
          </div>
        </div> -->
            <div class="disposition row">
                <div class="title-dispo-footerL">
                    <!-- Assureur : {{currentAssureur?.nom}} -->
                </div>
                <div class="title-dispo-footerR">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : {{email}}
                </div>
            </div>

        </div>
        <!-- <div class="footer-devis row">
        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
          <div class="fist-line">
            <b class="name-devis">{{currentAssureur?.nom}}</b> est une <b>{{currentAssureur?.typeSociete}}</b> avec
            conseil
            d'administration au capital de <b>{{convertStringToNumber(currentAssureur?.capital) |
              number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur?.anneeCreation}}</b>. NINEA
            <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur?.registreCommerce}}</b> Elle est régie par le
            code
            des assurances CIMA. Son siège est situé à <b>{{currentAssureur?.adresse}}</b>.
          </div>
          <div class="second-line">Tel:&nbsp; <b>{{currentAssureur?.telephone}}</b> &nbsp;&nbsp;Email:
            &nbsp;<b>{{currentAssureur?.email}}</b> </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
          <img class="footer-img" [src]="currentAssureur?.img" alt="">
        </div>
      </div> -->
    </div>
    <div *ngIf="isSouscription">
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left" (click)="modal.close('close')"><i
          class="fa fa-chevron-left"></i> Retour</button>
        <button type="button" class="btn btn-primary c-roundbtn btn-tel pull-left" (click)="openPDF()">Télécharger <i
          class="fa fa-download"></i></button>
        <button type="button" class="btn btn-success btn-next c-roundbtn pull-right" (click)="modal.close('subscribe')">
        Souscrire <i class="fa fa-angle-double-right"></i>
      </button>
    </div>
    <div *ngIf="!isSouscription" class="btnSous">

        <button type="button" class="btn btn-primary btn-tel pull-left" (click)="openPDF()"><i
          class="fa fa-download"></i></button>

    </div>
</ng-template>
<ng-template #contentResultlogin let-modal>
    <div class="modal-body login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="email" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>
<!-- inscription -->
<!-- <ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/images/logo.png"> </div>
                                    <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone <span
                          class="text-red">*</span></label> <input type="text" id="tel" placeholder="" class="form-control" formControlName="tel" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</ng-template> -->

<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Nom</label>
                                        <input type="text" id="nom" placeholder="" class="form-control" formControlName="nom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Prénom
                                        </label> <input type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                            mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro
                                            téléphone</label> <input type="text" id="telephone" placeholder="" class="form-control" formControlName="telephone" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('telephone')?.errors?.required && (inscriptionForm.get('telephone')?.touched || inscriptionForm.get('telephone')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- inscription -->
<!-- <ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->

<ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div> -->
                                    <h6 class="msg-info text-center">Mot de passe oublié ?</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #contentResultResetPassword let-modal>
    <div class="modal-body login">
        <form [formGroup]="resetPasswordForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Code OTP</label>
                                        <input type="text" id="token" placeholder="" class="form-control" formControlName="token">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('token')?.errors?.required && (resetPasswordForm?.get('token')?.touched || resetPasswordForm.get('token')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe
                                        </label> <input type="text" id="newPassword" placeholder="" class="form-control" formControlName="newPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('newPassword')?.errors?.required && (resetPasswordForm.get('newPassword')?.touched || resetPasswordForm.get('newPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="form-group"> <label class="form-control-label text-muted">Confirmation de mot de passe
                                    </label> <input type="text" id="confirmPassword" placeholder="" class="form-control" formControlName="confirmPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.required && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="resetPassword()" [disabled]="loaderVisible">Changez votre mot de passe</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Changez de mot de passe en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!--start me rappeler -->
<ng-template #etreRappeller let-modal>
    <form [formGroup]="rappellerForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card2 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Me faire rappeler</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                <label for="emai" class="form__label">Nom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('nom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                <label for="prenom" class="form__label">Prénom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('prenom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                <label for="emai" class="form__label">Email<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('email').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="telephone" class="form__input" autocomplete="off" placeholder="Téléphone" formControlName="telephone" />
                                <label for="telephone" class="form__label">Téléphone<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('telephone').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Disponibilité" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="disponibilite" formControlName="disponibilite" name="product" #disponibilite (change)="checkDisponiblite()">
                                    <option value="" disabled selected>Disponibilité</option>
                                    <option *ngFor="let dispo of disponibiteList" [value]="dispo.value">{{dispo?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Disponibilité<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('disponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="rappellerForm.get('disponibilite').value =='Soir' || rappellerForm.get('disponibilite').value =='Matin'">
                            <div class="form" placement="top" ngbPopover="Quelle heure" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="heureDisponibilite" formControlName="heureDisponibilite" #heureDisponibilite>
                                    <option value="" disabled selected>Quelle heure</option>
                                    <option *ngFor="let heure of heureDisponibiliteListTemoin" [value]="heure.value">{{heure?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Quelle heure<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('heureDisponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="meRappeller()" [disabled]="sendingRequestRappel">
                  <i class="fa fa-pencil" *ngIf="!sendingRequestRappel"></i> 
                  <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequestRappel"></i>
                  Valider
                </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- end me rappeler -->


<!-- Modal pour étre rappelé -->
<ng-template #devisAEnvoye let-modal>
    <form [formGroup]="etrerappeleForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card0 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">{{assureurAEnvoye?.displayMe}}</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Date de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="date" placeholder="" class="form__select" name="date" formControlName="date" name="date" #vehicule>
                          <option value="" disabled selected>Jour de rappel</option>
                          <option *ngFor="let rapel of dateRappel" [value]="rapel.value">{{rapel?.name}}</option>
                        </select>
                                <label for="cars" class="form__label">Jour de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="etrerappeleForm.get('date').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Heure de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa-regular fa-clock form__icon c-icon"></i>
                                <div class="form__separator">
                                </div>
                                <ngx-bootstrap-multiselect formControlName="heure" name="heure" class="form__select" [texts]="myTexts" [settings]="mySettings" #heure [options]="heureRappel"></ngx-bootstrap-multiselect>
                                <label for="cars" class="form__label">Heure de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf=" etrerappeleForm.get('heure').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                    <!-- <div class="noDataGaranties" *ngIf="garantie?.length == 0 ">
                Chargemet en cours ...
              </div> -->
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="etreRappele()" [disabled]="updateRequest">
                <i class="fa fa-pencil" *ngIf="!updateRequest"></i> 
                <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
                Valider
              </button>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- </div> -->
    </form>
</ng-template>
<swal #confirmSubscription2 [reverseButtons]="true" title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI,  BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>

<ng-template #mypop>
    1.Pour moi-même : mon nom est sur la carte à grise
    <br>2.Pour un tiers :pour une tierce personne
    <br>3.Pour le compte de :c'est ma voiture mais mon nom n'est pas sur la carte à grise
</ng-template>